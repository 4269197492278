import { Container, Text, Title, createStyles } from '@mantine/core'
import { Dots } from './svgs/Dots'

const useStyles = createStyles(theme => ({
  wrapper: {
    position: 'relative',
    paddingTop: 120,
    paddingBottom: 80,

    '@media (max-width: 755px)': {
      paddingTop: 80,
      paddingBottom: 60,
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  dots: {
    position: 'absolute',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],

    '@media (max-width: 755px)': {
      display: 'none',
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: 'center',
    fontSize: 50,
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,

    '@media (max-width: 520px)': {
      fontSize: 28,
      textAlign: 'left',
    },
  },

  description: {
    textAlign: 'center',

    '@media (max-width: 520px)': {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
  },
}))

export function TSAHero({ title, description }: { title: string; description?: string }) {
  const { classes } = useStyles()

  return (
    <Container className={classes.wrapper} size={1400}>
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />
      <div className={classes.inner}>
        <Title className={classes.title}>
          {title.split('**').map((elt, idx) => {
            return idx % 2 === 0 ? (
              <Text key={elt} component="span">
                {elt}
              </Text>
            ) : (
              <Text key={elt} component="span" color="orange" inherit>
                {elt}
              </Text>
            )
          })}
        </Title>

        {description && (
          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              {description}
            </Text>
          </Container>
        )}
      </div>
    </Container>
  )
}
